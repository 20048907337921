export const appAdminsList = [
  'phonkhoi',
  'hanwooll',
  'vkryukov',
  'malpulki',
  'rushisp',
  'luiacs',
  'peyko',
  'bbrwnhx',
  'epula',
  'apr',
  'boydshw',
  'fseqmari',
  'pedrmach',
  // Quad team
  'peteleai',
  'pentbria',
  'wiwnicol',
  'hrrmma',
  // F3RS
  'quesadka',
  'barqkevi',
  'fseqmari',
  'opportug',
  'sebapere',
];
export const metaDataAdminsList = [
  ...appAdminsList,
  // TODO: add Circulator's promo approvers aliases here
];
